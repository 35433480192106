<template>
  <v-card>
    <v-card-title>{{ title }} {{ date }}</v-card-title>
    <v-card class="list-card" elevation="0">
      <v-card-text v-if="dados.length != 0">
        <v-list>
          <template v-for="(item, i) in dados">
            <v-list-item :key="i">
              <div class="d-flex justify-space-between"></div>
              <v-list-item-content>
                <v-list-item-title>{{
                  `${item.id_indicador} - ${item.indicador}`
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="title != 'Metas'">{{
                  item.valor | BrazilianCurrency
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{
                  item.meta_qtde
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`${i}-divider`"></v-divider>
          </template>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        <p class="text-center">
          Não há dados para serem apresentados
        </p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import currencyMixin from "@/mixins/currencyMixin";
import dateMixin from "@/mixins/dateMixin";
export default {
  name: "CardReceita",

  mixins: [currencyMixin, dateMixin],

  props: {
    dados: {
      type: Array
    },
    title: {
      type: String
    },
    date: {
      type: String
    },
    hiddenValue: {
      type: Boolean
    }
  },

  data() {
    return {};
  }
};
</script>

<style scoped>
.list-card {
  min-height: 350px !important;
  max-height: 350px !important;
  overflow-y: scroll !important;
}

.comissao {
  text-align: right;
  font-size: 0.8rem;
}

.space-value {
  background-color: #e4e4e4;
  width: 65px;
  height: 14px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(15, 42, 73);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(15, 42, 73, 0.3);
  border-radius: 20px;
}
</style>
